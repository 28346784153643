/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    code: "code",
    strong: "strong",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "migrate-from-styled-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#migrate-from-styled-components",
    "aria-label": "migrate from styled components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Migrate from styled-components"), "\n", React.createElement(_components.p, null, "Differences with styled-components and migration guide to xstyled."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "differences-with-styled-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#differences-with-styled-components",
    "aria-label": "differences with styled components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Differences with styled-components"), "\n", React.createElement(_components.h3, {
    id: "declarative-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#declarative-components",
    "aria-label": "declarative components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Declarative components"), "\n", React.createElement(_components.p, null, "xstyled is a kind of styled-components with super powers. It adds lot of utilities accessible on a special ", React.createElement(_components.code, null, "x.*"), " component or directly using original ", React.createElement(_components.code, null, "styled.*"), " syntax."), "\n", React.createElement(_components.p, null, "The philosophy of xstyled is to use ", React.createElement(_components.code, null, "x.*"), " as much as possible."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using styled-components")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from 'styled-components'\n\nconst Button = styled.button`\n  padding: 0.5rem 1rem;\n  color: white;\n  border-radius: 0.375rem;\n  font-weight: 600;\n  transition: background-color cubic-bezier(0.4, 0, 0.2, 1) 150ms;\n  background-color: #10b981;\n\n  &:hover {\n    background-color: #065f46;\n  }\n\n  &:focus {\n    outline: none;\n    box-shadow: 0 0 0 3px #10b98180;\n  }\n`\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { x } from '@xstyled/styled-components'\n\nconst Button = (props) => (\n  <x.button\n    py={2}\n    px={4}\n    color=\"white\"\n    borderRadius=\"md\"\n    fontWeight=\"semibold\"\n    transition\n    bg={{ _: 'emerald-500', hover: 'emerald-800' }}\n    outline={{ focus: 'none' }}\n    ring={{ focus: true }}\n    ringColor={{ focus: 'emerald-500-a50' }}\n    {...props}\n  />\n)\n")), "\n", React.createElement(_components.p, null, "As you can see xstyled is fully declarative and much more productive than using styled-components."), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "/docs/declarative-components/"
  }, "declarative components guide"), " to learn more about this approach."), "\n", React.createElement(_components.h3, {
    id: "enhanced-styled-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#enhanced-styled-components",
    "aria-label": "enhanced styled components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Enhanced styled components"), "\n", React.createElement(_components.p, null, "We know you could not be convinced by ", React.createElement(_components.code, null, "x.*"), " approach. That's why xstyled could help you to be more productive with ", React.createElement(_components.code, null, "styled.*"), " approach too."), "\n", React.createElement(_components.p, null, "Most projects follow a design system, a set of space, colors, fonts.. that define the constraints and the universe of a project."), "\n", React.createElement(_components.p, null, "To follow a design system with styled-components, the common way is to use the theme."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using styled-components")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from 'styled-components'\n\nconst Button = styled.button`\n  padding: ${(p) => `${p.theme.space[2]} ${p.theme.space[4]}`};\n  color: ${(p) => p.theme.colors.white};\n  border-radius: ${(p) => p.theme.radii.md};\n  font-weight: ${(p) => p.theme.fontWeights.semibold};\n  transition: ${(p) => p.theme.transitions.default};\n  background-color: ${(p) => p.theme.colors['emerald-500']};\n\n  &:hover {\n    background-color: ${(p) => p.theme.colors['emerald-800']};\n  }\n\n  &:focus {\n    outline: none;\n    box-shadow: ${(p) => p.theme.shadows['emerald-ring']};\n  }\n`\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '@xstyled/styled-components'\n\nconst Button = styled.button`\n  padding-top: 2;\n  padding-bottom: 2;\n  padding-right: 4;\n  padding-left: 4;\n  color: white;\n  border-radius: md;\n  font-weight: semibold;\n  transition: default;\n  background-color: emerald-500;\n\n  &:hover {\n    background-color: emerald-800;\n  }\n\n  &:focus {\n    outline: none;\n    box-shadow: emerald-ring;\n  }\n`\n")), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "/docs/magic-styled-components/"
  }, "enhanced styled components guide"), " to learn more about this approach."), "\n", React.createElement(_components.h3, {
    id: "responsive-utilities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive-utilities",
    "aria-label": "responsive utilities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive utilities"), "\n", React.createElement(_components.p, null, "xstyled has great responsive utilities to simplify usage of media queries."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using styled-components")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from 'styled-components'\n\nconst Button = styled.div`\n  width: 200px;\n\n  @media (min-width: 768px) {\n    width: 300px;\n  }\n`\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled's ", React.createElement(_components.code, null, "x.*"))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { x } from '@xstyled/emotion'\n\nconst Button = ({ children }) => {\n  return <x.button w={{ _: 200, md: 300 }}>{children}</x.button>\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled's ", React.createElement(_components.code, null, "styled.*"))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '@xstyled/styled-components'\n\nconst Button = styled.div`\n  width: 200;\n\n  @media (min-width: md) {\n    width: 300;\n  }\n`\n")), "\n", React.createElement(_components.h2, {
    id: "replacing-styled-components-by-xstyled",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#replacing-styled-components-by-xstyled",
    "aria-label": "replacing styled components by xstyled permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Replacing styled-components by xstyled"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "@xstyled/styled-components"), " is a drop-in replacement for ", React.createElement(_components.code, null, "styled-components"), ". You can safely replace ", React.createElement(_components.code, null, "styled-components"), " by ", React.createElement(_components.code, null, "@xstyled/styled-components"), "."), "\n", React.createElement(_components.p, null, "Once your code is using xstyled, you can start using ", React.createElement(_components.a, {
    href: "/docs/declarative-components/"
  }, "declarative components (", React.createElement(_components.code, null, "x.*"), ")"), " and ", React.createElement(_components.a, {
    href: "/docs/styled-components/"
  }, "enhanced styled components (", React.createElement(_components.code, null, "styled.*"), ")"), "."), "\n", React.createElement(_components.h3, {
    id: "css-prop-is-not-supported",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#css-prop-is-not-supported",
    "aria-label": "css prop is not supported permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "css"), " prop is not supported"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://styled-components.com/docs/api#css-prop"
  }, "Styled Components ", React.createElement(_components.code, null, "css"), " prop"), " is not supported by xstyled. We don't plan to support it since xstyled philosophy is to use ", React.createElement(_components.code, null, "x.*"), " instead."), "\n", React.createElement(_components.p, null, "Styled Components ", React.createElement(_components.code, null, "css"), " prop rely on a Babel transformation to work. Some edge-cases like using state in a CSS property are not supported. We recommended to not use it. If your codebase uses it, then you should migrate to xstyled's ", React.createElement(_components.code, null, "x.*"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "❌ The following code does not work even with just styled-components")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function Example() {\n  const [value, setValue] = useState(false)\n  return (\n    <div\n      css={`\n        color: ${value ? 'red' : 'blue'};\n      `}\n    />\n  )\n}\n")), "\n", React.createElement(_components.h2, {
    id: "babel-plugin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#babel-plugin",
    "aria-label": "babel plugin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Babel plugin"), "\n", React.createElement(_components.p, null, "You may want to use ", React.createElement(_components.a, {
    href: "https://styled-components.com/docs/tooling#babel-plugin"
  }, "styled-components Babel plugin"), " to add support for server-side rendering, minification of styles, and a nicer debugging experience."), "\n", React.createElement(_components.p, null, "You can use it out of the box, but you have to specify xstyled as ", React.createElement(_components.code, null, "topLevelImportPaths"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// .babelrc\n{\n  \"plugins\": [\n    [\n      \"babel-plugin-styled-components\",\n      {\n        \"topLevelImportPaths\": [\n          \"@xstyled/styled-components\",\n          \"@xstyled/styled-components/no-tags\",\n          \"@xstyled/styled-components/native\",\n          \"@xstyled/styled-components/primitives\"\n        ],\n      }\n    ]\n  ]\n}\n")), "\n", React.createElement(_components.h2, {
    id: "babel-macro",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#babel-macro",
    "aria-label": "babel macro permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Babel Macro"), "\n", React.createElement(_components.p, null, "You may want to use ", React.createElement(_components.a, {
    href: "https://styled-components.com/docs/tooling#babel-macro"
  }, "styled-components Babel macro"), " to use Babel plugin with zero-configuration."), "\n", React.createElement(_components.p, null, "To use it, follow ", React.createElement(_components.a, {
    href: "https://styled-components.com/docs/tooling#babel-macro"
  }, "styled-components guide"), " and add this configuration in your ", React.createElement(_components.code, null, "package.json"), " or any other ", React.createElement(_components.a, {
    href: "https://github.com/kentcdodds/babel-plugin-macros"
  }, "babel-plugin-macros"), " config file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// babel-plugin-macros.config.js\nmodule.exports = {\n  // ...\n  // Other macros config\n  styledComponents: {\n    importModuleName: '@xstyled/styled-components',\n  },\n}\n")), "\n", React.createElement(_components.p, null, "You don't need to specify additional ", React.createElement(_components.code, null, "topLevelImportPaths"), " config when you use babel macro."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
